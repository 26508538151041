<template>
  <div class="container">
    <div
      v-if="isLoading"
      class="spinner-grow d-flex align-items-center justify-content-center"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else>
      <h2 class="text-center pt-0">{{ getTimeOfDay() }}</h2>
      <h5 class="text-center">Select Date:</h5>
      <div class="input-group input-group-static p-0 justify-content-center">
        <material-button
          variant="outline"
          color="info"
          size="sm"
          @click="previousDay"
          >&lt;</material-button
        >
        <span class="d-flex align-items-center px-4">{{
          usermetrics.date
        }}</span>
        <material-button
          variant="outline"
          color="info"
          size="sm"
          @click="nextDay"
          >&rtri;</material-button
        >
      </div>
      <div class="card mb-2">
        <div class="card-header pl-0 pb-0 p-3">
          <h4>Nutrition</h4>
        </div>
        <div class="card-body pt-0 p-3">
          <material-input
            id="protein"
            v-model="usermetrics.calories"
            label="Calories"
            variant="static"
            type="number"
            class="mb-1"
          ></material-input>
          <material-input
            id="protein"
            v-model="usermetrics.protein"
            label="Protein"
            variant="static"
            type="number"
            class="mb-1"
          ></material-input>
          <material-progress
            class="mb-2"
            :percentage="proteinPercentage"
            style="height: 10px"
          ></material-progress>
          <material-input
            id="carbs"
            v-model="usermetrics.carbs"
            label="Carbs"
            variant="static"
            type="number"
            class="mb-1"
          ></material-input>
          <material-progress
            class="mb-3"
            :percentage="carbsPercentage"
            style="height: 10px"
          ></material-progress>
          <material-input
            id="fats"
            v-model="usermetrics.fat"
            label="Fats"
            variant="static"
            type="number"
            class="mb-1"
          ></material-input>
          <material-progress
            :percentage="fatPercentage"
            style="height: 10px"
          ></material-progress>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header pl-0 pb-0 p-3">
          <h4>Other Metrics</h4>
        </div>
        <div class="card-body p-3">
          <material-input
            id="sleep"
            v-model="usermetrics.sleep"
            label="Sleep"
            type="number"
            variant="static"
            class="mb-1"
          ></material-input>
          <material-input
            id="steps"
            v-model="usermetrics.steps"
            label="Steps"
            type="number"
            variant="static"
            class="mb-1"
          ></material-input>
          <material-input
            id="weight"
            v-model="usermetrics.weight"
            label="Weight"
            type="number"
            variant="static"
            class="m-0"
            min="1"
          ></material-input>
          <div v-if="usermetrics.weight === '0'" class="text-danger">Enter a weight greater than 0</div>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header pl-0 pb-0 p-3">
          <h4>Notes</h4>
        </div>
        <div class="card-body p-3">
          <material-textarea
            id="notes"
            v-model="usermetrics.notes"
            label="Notes"
          ></material-textarea>
          <material-button
            class="my-4 mb-0"
            variant="gradient"
            color="info"
            full-width
            @click="saveData"
            >Save</material-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supabase from "../../data/createClient.js";
import UserSettings from "../../data/user/userSettings.js";
import MaterialInput from "../../components/MaterialInput.vue";
import MaterialTextarea from "../../components/MaterialTextarea.vue";
import MaterialButton from "../../components/MaterialButton.vue";
import MaterialProgress from "../../components/MaterialProgress.vue";
import DateUtils from "../../common/dateutils.js";
import { logError } from "../../errors/rollbar.js";

export default {
  name: "NutritionLog",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialProgress,
  },
  data() {
    return {
      usermetrics: {
        id: "",
        userid: "",
        date: DateUtils.formatLocalDate(),
        calories: "",
        protein: "",
        carbs: "",
        fat: "",
        sleep: "",
        steps: "",
        weight: "",
        notes: "",
        complete: false,
      },
      ableToContinue: false,
      macroGoals: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      },
      isLoading: true,
    };
  },
  computed: {
    caloriesPercentage() {
      if (!this.usermetrics.calories) return 0;

      const calories = parseInt(this.usermetrics.calories);

      return Math.round((calories / this.macroGoals.calories) * 100);
    },
    proteinPercentage() {
      if (!this.usermetrics.protein) return 0;

      const protein = parseInt(this.usermetrics.protein);

      return Math.round((protein / this.macroGoals.protein) * 100);
    },
    carbsPercentage() {
      if (!this.usermetrics.carbs) return 0;

      const carbs = parseInt(this.usermetrics.carbs);

      return Math.round((carbs / this.macroGoals.carbs) * 100);
    },
    fatPercentage() {
      if (!this.usermetrics.fat) return 0;

      const fat = parseInt(this.usermetrics.fat);

      return Math.round((fat / this.macroGoals.fat) * 100);
    },
  },
  async mounted() {
    this.usermetrics.userid = await UserSettings.getUserId();

    await this.loadUserMetrics(this.usermetrics.date);
    if (!this.macroGoals || !this.macroGoals.calories) {
      await this.getMacros();
    }

    this.isLoading = false;
  },
  methods: {
    async loadUserMetrics(date) {
      const userId = this.usermetrics.userid;

      const { data, error } = await supabase
        .from("usermetrics")
        .select()
        .eq("date", date)
        .limit(1);

      //sum the calories, protein, carbs and fats from a table called macro details for the date and user id.

      if (error) {
        this.usermetrics = {
          date: date,
          userid: userId,
        };
        console.log(error.message);
      } else {
        const item = data[0];

        if (!item) {
          this.usermetrics = {
            date: date,
            userid: userId,
          };
          return;
        }
        this.usermetrics = {
          id: item.id,
          userid: userId,
          date: item.date,
          calories: item.calories,
          protein: item.protein,
          carbs: item.carbs,
          fat: item.fats,
          sleep: item.sleep,
          steps: item.steps,
          weight: item.weight,
          notes: item.notes,
          complete: item.complete,
        };
      }
    },
    async saveData() {

      if (this.usermetrics.weight === "0") {
        return;
      }

      const { error } = await supabase.from("usermetrics").upsert(
        {
          id: this.usermetrics.id ? this.usermetrics.id : undefined,
          userid: this.usermetrics.userid,
          date: new Date(this.usermetrics.date),
          calories: parseFloat(this.usermetrics.calories),
          protein: parseFloat(this.usermetrics.protein),
          carbs: parseFloat(this.usermetrics.carbs),
          fats: parseFloat(this.usermetrics.fat),
          sleep: parseFloat(this.usermetrics.sleep),
          steps: parseInt(this.usermetrics.steps),
          weight: parseFloat(this.usermetrics.weight),
          notes: this.usermetrics.notes,
          complete: true,
        },
        { onConflict: "id" }
      );

      if (error) {
        logError(error);
      } else {
        this.$swal.fire({
          icon: "success",
          title: "Saved",
          text: "Your data has been saved",
        });
        console.log(this.usermetrics.notes);
      }
    },
    getTimeOfDay() {
      const date = new Date();
      const hours = date.getHours();
      if (hours < 12) {
        return "Good Morning";
      } else if (hours < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    async beforeChangeDate() {
      if (this.usermetrics.complete) {
        return true;
      }

      if (this.ableToContinue) {
        return true;
      }

      const { isConfirmed } = await this.$swal.fire({
        title: "Are you sure?",
        text: "You haven't saved your data for today!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue, Dont ask me again",
        cancelButtonText: "Cancel!",
      });

      if (!isConfirmed) {
        return false;
      } else {
        this.ableToContinue = true;
        return true;
      }
    },
    async nextDay() {
      if (!(await this.beforeChangeDate())) {
        return;
      }

      const date = new Date(this.usermetrics.date);
      date.setDate(date.getDate() + 1);
      this.usermetrics.date = date.toISOString().slice(0, 10);

      await this.loadUserMetrics(this.usermetrics.date);
    },
    async previousDay() {
      if (!(await this.beforeChangeDate())) {
        return;
      }

      const date = new Date(this.usermetrics.date);
      date.setDate(date.getDate() - 1);
      this.usermetrics.date = date.toISOString().slice(0, 10);
      await this.loadUserMetrics(this.usermetrics.date);
    },
    async getMacros() {
      if (!this.usermetrics.userid) return console.error("No user id provided");

      try {
        const { data: id, error: iderrors } = await supabase
          .from("users")
          .select("nutritiondetailid")
          .eq("id", this.usermetrics.userid)
          .limit(1);

        if (iderrors || id.length === 0) {
          console.error("Error fetching data:", error.message);
          return;
        }

        const { data, error } = await supabase
          .from("nutritiondetails")
          .select("calories, protein, carbs, fats, users!inner(id)")
          .eq("id", id[0].nutritiondetailid)
          .limit(1);

        if (error || data.length === 0) {
          console.error("Error fetching data:", error.message);
        } else {
          this.macroGoals.calories = data[0].calories;
          this.macroGoals.protein = data[0].protein;
          this.macroGoals.carbs = data[0].carbs;
          this.macroGoals.fat = data[0].fats;
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    },
  },
};
</script>
